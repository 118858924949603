import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Styles
import "./hamburger.css";

const Nav = styled.nav`
  padding: 10px 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  & .active-link {
    color: #c4c4c4;
  }

  & a,
  & button {
    color: ${props => props.textColor} !important;
  }

  background-color: ${props => props.backgroundColor};

  & .hamburger.is-active .hamburger-inner::before,
  & .hamburger.is-active .hamburger-inner::after,
  & .hamburger-inner::before,
  & .hamburger-inner::after {
    background-color: ${props => props.textColor};
  }
`;

const MenuList = styled.ol`
  display: ${props => (props.display ? `block` : `none`)};
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;

  margin: 0;
  padding: 0;

  list-style: none;

  & li {
    padding: 15px 0 0 0;

    & a {
      font-size: 18px;
      line-height: 26px;

      color: ${props => props.textColor};

      &:hover {
        color: #c4c4c4;
      }
    }
  }
`;

export const MobileMenu = ({ location, textColor, menuItems }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const mobileMenuItems = menuItems.map((link, index) => {
    return (
      <li key={`menu_navigation_id_${index}`}>
        <Link
          to={`/${link.links.document.uid}`}
          activeClassName="active-link"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          {link.links.document.data.title.text}
        </Link>
      </li>
    );
  });

  return (
    <Nav
      textColor={location === "/" ? textColor : "#000"}
      backgroundColor={
        isMobileMenuOpen
          ? location === "/" && textColor === "#fff"
            ? `#000`
            : `#fff`
          : `transparent`
      }
    >
      <Link to={`/`} onClick={() => setIsMobileMenuOpen(false)}>
        A Delicate Sight
      </Link>

      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className={`hamburger hamburger--spring ${isMobileMenuOpen &&
          `is-active`}`}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>

      <MenuList className="right" display={isMobileMenuOpen}>
        {mobileMenuItems}
      </MenuList>
    </Nav>
  );
};
