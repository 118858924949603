import React, { createContext } from "react";

const HeaderTextColor = createContext({
  headerTextColor: "#fff",
  setHeaderTextColor: () => {},
});

export class HeaderTextColorProvider extends React.Component {
  setHeaderTextColor = newHeaderTextColor => {
    this.setState({ headerTextColor: newHeaderTextColor });
  };

  state = {
    headerTextColor: "#fff",
    setHeaderTextColor: this.setHeaderTextColor,
  };

  render() {
    return (
      <HeaderTextColor.Provider value={this.state}>
        {this.props.children}
      </HeaderTextColor.Provider>
    );
  }
}

export const HeaderTextColorConsumer = HeaderTextColor.Consumer;
