import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Div100vh from "react-div-100vh";

const fadeBackground = keyframes`
  from {
    background-color: #fff;
  }
  to {
    background-color: #000;
  }
`;

const SplashWrapper = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  cursor: pointer;

  z-index: 20000;

  animation: ${fadeBackground} 5s alternate;
  opacity: ${props => (props.disable ? 0 : 1)};
  transition: 1s opacity ease;
  display: ${props => (props.hide ? "none" : "block")};
`;

const fadeText = keyframes`
  from {
    color: #000;
  }

  to {
    color: #fff;
  }
`;

const TitleBox = styled.div`
  width: 100%;
  margin: 0;

  text-align: center;
  color: #fff;

  animation: ${fadeText} 2.5s alternate;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & h1 {
    font-size: min(max(40px, 8vw), 150px);
    line-height: 1;

    text-align: center;
  }
`;

export const Splash = ({ location }) => {
  const [hide, hideSplash] = useState(false);
  const [slide, slideUp] = useState(false);
  const [disable, disableSplash] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      slideUp(true);
      disableSplash(true);

      const hideTimer = setTimeout(() => {
        hideSplash(true);
      }, 500);
      return () => clearTimeout(hideTimer);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      if (hide === false) {
        document.body.style.overflow = "hidden";
      }

      if (hide === true) {
        document.body.style.overflow = "";
      }
    }
  }, [hide]);

  const triggerDisableSplash = () => {
    hideSplash(true);
    slideUp(true);
    disableSplash(true);
  };

  if (location === "/") {
    return (
      <SplashWrapper
        hide={hide}
        disable={disable}
        onClick={() => triggerDisableSplash()}
      >
        <Div100vh
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <TitleBox hide={hide} slide={slide} disable={disable}>
            <h1>A Delicate Sight</h1>
          </TitleBox>
        </Div100vh>
      </SplashWrapper>
    );
  } else {
    return null;
  }
};
