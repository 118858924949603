import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    color: #000;
    background-color: #fff;

    font-family: 'Spectral', 'Times New Roman', serif;
    font-style: normal;
    font-weight: 200;
    font-feature-settings: "liga";

    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;

    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;

    // text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    // overflow-x: hidden;

    @media(max-width: 1000px){
      font-size: 18px;
      line-height: 26px;
    }

  }


  b,
  strong {
    font-weight: normal;
  }

  a,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  a:hover{
    color: #c4c4c4;
  }

  a:focus{
    outline: none;
  }


  h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-weight: 200;
  }

  h1, h2 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.01em;
  }


  button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    border: 0;
    background-color: transparent;

    color: #000;

    &:hover {
    color: #000;
    }

    &:focus {
      outline: 0;
    }

  }

  img {
    width: 100%;
    display: block;
  }

  /*--------------------------------------------------------------
  # Cookie Consent
  --------------------------------------------------------------*/


.cookie-consent-container{
  position:fixed;
  right:30px;
  bottom:30px !important;

  @media(max-width: 767px){
    left: 20px;
    bottom: 20px !important;
  }

  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0;

  align-tems:baseline;

  background:rgba(255,255,255,0.9);
  color:#000;

  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;


  padding:15px;
  width:100%;
  max-width:310px;
  z-index:999;

  @media(max-width: 500px){
    max-width:280px;
  }

  border: 1px solid #c6c6c6;

  & a {
    text-decoration: underline;
    text-decoration-color: #c6c6c6;
  }

  & button{
    color: #000;
    font-size: 11px;
    letter-spacing: 0;


    border: 1px solid #c6c6c6;


    margin: 15px 0 0 0;
    padding: 7px 10px;

    &:hover{
      background-color: #c6c6c6;

    }
  }
}
`;

export default GlobalStyle;
