import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import Media from "react-media";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  background-color: ${props => props.bgColor};

  ${props =>
    props.bgColor === "transparent" &&
    `
transition: 1ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
transition-delay: 0;
`}

  ${props =>
    props.bgColor === "#fff" &&
    `
  transition: 750ms background-color cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-delay: 500ms;
`}

  // height: 50px;

  z-index: 200;

  // @media (max-width: 767px) {
  //   height: auto;
  // }
`;

const Header = ({ location, textColor }) => {
  const [windowWidth, setWindowWidth] = useState(768);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          menu_items {
            links {
              document {
                ... on PrismicTeachingPack {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicInterviews {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicAbout {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menuItems = data.prismicMenu.data.menu_items.map((link, index) => {
    return (
      <li key={`menu_navigation_id_${index}`}>
        <Link to={`/${link.links.document.uid}`} activeClassName="active-link">
          {link.links.document.data.title.text}
        </Link>
      </li>
    );
  });

  return (
    <HeaderWrapper
      bgColor={
        location === "/"
          ? textColor === "#fff"
            ? "transparent"
            : "#fff"
          : `#fff`
      }
    >
      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => (
          <MobileMenu
            location={location}
            textColor={textColor}
            menuItems={data.prismicMenu.data.menu_items}
          />
        )}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <DesktopMenu
            location={location}
            textColor={textColor}
            menuItems={menuItems}
          />
        )}
      />
    </HeaderWrapper>
  );
};

export default Header;
