import React from "react";
import { Helmet } from "react-helmet";
import { Location } from "@reach/router";
import styled, { keyframes } from "styled-components";
import { BaseCSS, GridThemeProvider } from "styled-bootstrap-grid";
import { Normalize } from "styled-normalize";

// Components
import GlobalStyles from "../styles/globalStyles";
import App from "./app";
import { Splash } from "./splash";
// import DefaultSEO from "./default-seo";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  animation: 2.5s ${fadeIn};
`;

const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 30,
  },
  col: {
    padding: 30,
  },
  container: {
    padding: 30,
    maxWidth: {
      xxl: 1141,
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const Layout = ({ children }) => (
  <GridThemeProvider gridTheme={gridTheme}>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;1,200&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Location>
      {({ location }) => {
        return (
          <Container location={location.pathname}>
            <Normalize />
            <BaseCSS />
            <GlobalStyles />
            <App location={location} children={children} />
            <Splash location={location.pathname} />
          </Container>
        );
      }}
    </Location>
  </GridThemeProvider>
);

export default Layout;
