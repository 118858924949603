import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Nav = styled.nav`
  padding: 0 30px;
  max-width: 1400px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    margin: 0;
    padding: 0;

    list-style: none;

    & li {
      padding-right: 50px;

      &:last-of-type {
        padding-right: 0;
      }

      & a {
        font-size: 20px;
        line-height: 50px;

        color: ${props => props.textColor};

        &:hover {
          color: #c4c4c4;
        }

        @media (max-width: 1000px) {
          font-size: 18px;
          line-height: 40px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }

  & .active-link {
    color: #c4c4c4;
  }
`;

export const DesktopMenu = ({ location, textColor, menuItems }) => (
  <Nav textColor={location === "/" ? textColor : "#000"}>
    <ol className="left">{menuItems}</ol>

    {(textColor === "#000" ||
      location.includes(`/film`) ||
      location.includes(`/film/`) ||
      location.includes(`/about`) ||
      location.includes(`/about/`) ||
      location.includes(`/guide`) ||
      location.includes(`/guide/`) ||
      location.includes(`/privacy-policy`) ||
      location.includes(`/privacy-policy/`)) && (
      <ol className="right">
        <li>
          <Link to={`/`}>A Delicate Sight</Link>
        </li>
      </ol>
    )}
  </Nav>
);
