import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

// Context
import {
  HeaderTextColorProvider,
  HeaderTextColorConsumer,
} from "../context/header-text-color";

// Components
import Header from "./header";

const Main = styled.main`
  flex: 1;

  margin: ${props => props.margin};
`;

const Container = styled.div`
  width: 100%;
  max-width: 1400px;

  padding: 0 30px;
  margin: 0 auto;

  // @media (max-width: 767px) {
  //   padding: 0 20px;
  // }
`;

const fadeBackground = keyframes`
  from {
    background-color: #fff;
  }
  to {
    background-color: #000;
  }
`;

const App = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          text {
            text
          }
        }
      }
    }
  `);
  return (
    <Container>
      <Helmet
        meta={[
          {
            name: "description",
            content: `${data.prismicHomepage.data.text.text.substring(0, 240)}`,
          },
        ]}
      />
      <HeaderTextColorProvider>
        <HeaderTextColorConsumer>
          {({ headerTextColor }) => (
            <>
              <Header
                location={location.pathname}
                textColor={headerTextColor}
              />
              <Main margin={location.pathname === "/" ? `0` : `50px 0`}>
                {children}
              </Main>
            </>
          )}
        </HeaderTextColorConsumer>
      </HeaderTextColorProvider>

      <CookieConsent
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="cookie-consent-container"
        disableStyles={true}
      >
        This website uses cookies to enhance the user experience. Close this
        dialog to confirm you are happy with that, or find out more in our{" "}
        <Link to={`/privacy-policy`}>privacy policy</Link>.
      </CookieConsent>
    </Container>
  );
};

export default App;
